import React from 'react';
import FontFaceLoader from './src/fonts/FontFaceLoader';

const wrapPageElement = ({ element }) => {
  return (
    <>
      <FontFaceLoader>{element}</FontFaceLoader>
    </>
  );
};
export { wrapPageElement };
