/** @jsx jsx */
import React, { useEffect } from 'react';
import { Global, css, jsx } from '@emotion/react';
import * as FontFace from './FontFaces';

const fonts = Object.values(FontFace);
const FontFaceObserver = require('fontfaceobserver');

export default function FontFaceLoader({ children }) {
  useEffect(() => {
    const oberservers = [];
    fonts.map((item) => {
      oberservers.push(new FontFaceObserver(item.fontFamily, item).load());
    });

    Promise.all(oberservers)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  });

  return (
    <React.Fragment>
      <Global
        styles={fonts.map(({ fontFamily, fontWeight, fontStyle, fontUrls }) => {
          return css`
            @font-face {
              font-family: '${fontFamily}';
              font-weight: ${fontWeight};
              font-style: ${fontStyle};
              src: ${fontUrls.map((font) => `url("${font.src}") format("${font.format}")`)};
            }
          `;
        })}
      />
      {children}
    </React.Fragment>
  );
}
