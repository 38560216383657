import AustinLightSrc from './Austin-Light.woff2';
import AustinLightItalicSrc from './Austin-LightItalic.woff2';
import AustinRomanSrc from './Austin-Roman.woff2';
import AustinMediumSrc from './Austin-Medium.woff2';
import AustinBoldSrc from './Austin-Bold.woff2';

import BrandonGrotesqueThinSrc from './BrandonGrotesque-Thin.woff2';
import BrandonGrotesqueThinItalicSrc from './BrandonGrotesque-ThinItalic.woff2';
import BrandonGrotesqueLightSrc from './BrandonGrotesque-Light.woff2';
import BrandonGrotesqueLightItalicSrc from './BrandonGrotesque-LightItalic.woff2';
import BrandonGrotesqueRegularSrc from './BrandonGrotesque-Regular.woff2';
import BrandonGrotesqueRegularItalicSrc from './BrandonGrotesque-RegularItalic.woff2';
import BrandonGrotesqueMediumSrc from './BrandonGrotesque-Medium.woff2';
import BrandonGrotesqueMediumItalicSrc from './BrandonGrotesque-MediumItalic.woff2';
import BrandonGrotesqueBoldSrc from './BrandonGrotesque-Bold.woff2';
import BrandonGrotesqueBoldItalicSrc from './BrandonGrotesque-BoldItalic.woff2';
import BrandonGrotesqueBlackSrc from './BrandonGrotesque-Black.woff2';
import BrandonGrotesqueBlackItalicSrc from './BrandonGrotesque-BlackItalic.woff2';

const createFontFace = (options) => {
  return {
    ...options,
    toString() {
      return `${options.fontFamily}`;
    },
  };
};

export const AustinLight = createFontFace({
  fontFamily: 'Austin',
  fallback: 'sans-serif',
  fontWeight: 300,
  fontStyle: 'normal',
  fontUrls: [{ src: AustinLightSrc, format: 'woff2' }],
});

export const AustinLightItalic = createFontFace({
  fontFamily: 'Austin',
  fallback: 'sans-serif',
  fontWeight: 300,
  fontStyle: 'italic',
  fontUrls: [{ src: AustinLightItalicSrc, format: 'woff2' }],
});

export const AustinRoman = createFontFace({
  fontFamily: 'Austin',
  fallback: 'sans-serif',
  fontWeight: 400,
  fontStyle: 'normal',
  fontUrls: [{ src: AustinRomanSrc, format: 'woff2' }],
});

export const AustinMedium = createFontFace({
  fontFamily: 'Austin',
  fallback: 'sans-serif',
  fontWeight: 500,
  fontStyle: 'normal',
  fontUrls: [{ src: AustinMediumSrc, format: 'woff2' }],
});

export const AustinBold = createFontFace({
  fontFamily: 'Austin',
  fallback: 'sans-serif',
  fontWeight: 600,
  fontStyle: 'normal',
  fontUrls: [{ src: AustinBoldSrc, format: 'woff2' }],
});

export const BrandonGrotesqueThin = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 100,
  fontStyle: 'normal',
  fontUrls: [{ src: BrandonGrotesqueThinSrc, format: 'woff2' }],
});

export const BrandonGrotesqueThinItalic = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 100,
  fontStyle: 'italic',
  fontUrls: [{ src: BrandonGrotesqueThinItalicSrc, format: 'woff2' }],
});

export const BrandonGrotesqueLight = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 300,
  fontStyle: 'normal',
  fontUrls: [{ src: BrandonGrotesqueLightSrc, format: 'woff2' }],
});

export const BrandonGrotesqueLightItalic = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 300,
  fontStyle: 'italic',
  fontUrls: [{ src: BrandonGrotesqueLightItalicSrc, format: 'woff2' }],
});

export const BrandonGrotesqueRegular = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 400,
  fontStyle: 'normal',
  fontUrls: [{ src: BrandonGrotesqueRegularSrc, format: 'woff2' }],
});

export const BrandonGrotesqueRegularItalic = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 400,
  fontStyle: 'italic',
  fontUrls: [{ src: BrandonGrotesqueRegularItalicSrc, format: 'woff2' }],
});

export const BrandonGrotesqueMedium = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 500,
  fontStyle: 'normal',
  fontUrls: [{ src: BrandonGrotesqueMediumSrc, format: 'woff2' }],
});

export const BrandonGrotesqueMediumItalic = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 500,
  fontStyle: 'italic',
  fontUrls: [{ src: BrandonGrotesqueMediumItalicSrc, format: 'woff2' }],
});

export const BrandonGrotesqueBold = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 700,
  fontStyle: 'normal',
  fontUrls: [{ src: BrandonGrotesqueBoldSrc, format: 'woff2' }],
});

export const BrandonGrotesqueBoldItalic = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 700,
  fontStyle: 'italic',
  fontUrls: [{ src: BrandonGrotesqueBoldItalicSrc, format: 'woff2' }],
});

export const BrandonGrotesqueBlack = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 900,
  fontStyle: 'normal',
  fontUrls: [{ src: BrandonGrotesqueBlackSrc, format: 'woff2' }],
});

export const BrandonGrotesqueBlackItalic = createFontFace({
  fontFamily: 'Brandon Grotesque',
  fallback: 'sans-serif',
  fontWeight: 900,
  fontStyle: 'italic',
  fontUrls: [{ src: BrandonGrotesqueBlackItalicSrc, format: 'woff2' }],
});
